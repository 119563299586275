.input_div {
    position: relative;
}
.input_div svg {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}
.input_div input {
    padding-right: 44px;
}

.css-1wc848c-MuiFormHelperText-root {
    margin-left: 3px !important;
}
