@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hrp-overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20000;
    background-color: rgb(255, 255, 255, 0.1);
    cursor: wait !important;
}

#form:nth-child(1) button {
    display: none;
}

.css-1sszw6i-MuiButtonBase-root-MuiButton-root {
    font-size: 13px !important;
    line-height: 23px !important;
    text-transform: lowercase !important;
}

.checkbox label {
    cursor: pointer;
    width: 202px;
}
#p {
    color: red;
}
.error {
    color: #d32f2f;
    font-size: 12px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 3px 0 0;
}
.modal-body {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    width: 100%;
    height: 100%;
}
.modal-content {
    margin: auto;
    padding: 0 !important;
    background-color: transparent;
    border: none;
}
.h-25 {
    border-top: 1px solid #ddd;
}
.form-group.row.g-3.mb-4 {
    margin-top: 1px !important;
}
.img {
    width: 100%;
    height: 100%;
}
img {
    margin-left: 50px;
    width: 150px;
    height: 80px;
}
#mod_id {
    margin: 0 auto;
    width: 60vw;
    height: 35vw;
}
p {
    margin: 3px 0 0 0;
    padding: 0;
}
.card-brd {
    height: 100%;
    box-shadow: rgba(139, 139, 139, 0.45) 0px 4px 4px -2px, rgba(101, 101, 101, 0.48) 0px 0px 1px 1px;
    border-radius: 4px;
    padding-top: 20px !important;
}
.card-brd span {
    font-size: 13pt;
    padding: 0 12px;
    line-height: 30px;
    cursor: pointer;
    height: 33px;
    user-select: none;
}
#revSkills .card-brd {
    border: 1px solid #ddd;
}
.bi-trash {
    display: none;
    width: 100%;
    margin: 0 -4px 0 4px;
    /* padding: 0 -3px 0 0; */
}
.bi-trash:hover {
    color: rgb(77, 161, 234);
    color: rgb(0, 135, 132);
    color: rgb(77, 226, 234);
    color: rgb(252, 82, 116);
}
#lbl-list {
    display: none;
    font-size: 13px;
}
.skill_lbl {
    color: #aaa;
}

@media screen and (max-width: 1000px) {
    #mod_id {
        margin: 0 auto;
        width: 80vw !important;
        height: 50vw !important;
    }
    #img_span {
        width: 100% !important;
    }
}

#img_span {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
    background-color: rgb(226, 226, 226);
    padding: 7px 13px;
    border-radius: 3px;
}

#img_span > span,
#resume {
    text-decoration: underline;
    color: rgb(97, 97, 97);
    font-weight: 500;
    cursor: pointer;
}

.mat_label {
    font-size: 11px;
    color: rgba(129, 129, 129, 0.74);
    margin: 0;
    padding: 0;
    display: block;
}
.nav-link {
    user-select: none;
    pointer-events: none;
    cursor: not-allowed !important;
    background-color: #cccccc !important;
}
.active {
    background-color: rgb(242, 248, 255) !important;
    color: darkcyan !important;
}

/* Loader */
#h1 {
    margin: 0;
    padding: 0;
    font-family: ‘Arial Narrow’, sans-serif;
    font-weight: 600;
    font-size: 1.42em;
    color: #3f51b5;
}

#load {
    position: relative;
    top: 0.63em;
    display: inline-block;
    text-transform: uppercase;
    opacity: 0;
    transform: rotateX(-90deg);
}

.let1 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.1s;
}

.let2 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.2s;
}

.let3 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.3s;
}

.let4 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.4s;
}

.let5 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.5s;
}

.let6 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.6s;
}

.let7 {
    animation: drop 0.9s ease-in-out infinite;
    animation-delay: 0.7s;
}

@keyframes drop {
    10% {
        opacity: 0.5;
    }
    20% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }
    80% {
        opacity: 1;
        top: 3.78em;
        transform: rotateX(-360deg);
    }
    90% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        top: 6.94em;
    }
}

.qcont:first-letter {
    text-transform: capitalize;
}
