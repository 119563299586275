* {
    box-sizing: border-box;
}

.menu {
    width: 20%;
    text-align: center;
    background-image: url("./../assets/177-1776542_iphone-wallpaper-blue-sky.jpg");
}

li {
    list-style: none;
}
.menu a {
    background-color: #efefef;
    padding: 8px;
    margin-top: 7px;
    display: block;
    width: 100%;
    color: black;
    text-decoration: none;
    font-size: 1.5rem;
}

.main {
    padding: 0 20px;
}
.headTittle {
    justify-content: space-between;
}

@media only screen and (max-width: 620px) {
    /* For mobile phones: */
    .menu,
    .main {
        width: 100%;
    }
}

.active {
    background-color: lightsteelblue !important;
    color: darkcyan !important;
}
